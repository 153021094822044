import React from 'react';
import ReactDOM from 'react-dom';
import ReactCrop from 'react-image-crop';
import { animateScroll as scroll } from 'react-scroll';
import { Helmet } from 'react-helmet';

import './css/uikit.css';
import './App.css';
import 'react-image-crop/dist/ReactCrop.css';
import title from './images/title.png';
import Loading from './images/loading.gif';
import noimage from './images/noimage.png';
import twitter from './images/twitter.png';
import rotate from './images/rotate.png';
import go from './images/go.png';
import camera from './images/camera.png';
import help from './images/help.png';

var files;
var puturl;
var filepath;
var angle = 0;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 50,
        height: 50,
      },
      image_src: '',
      uploadURL: '',
      imagesubjectid: '',
      imagesubjectname: '',
      imagetopic: '',
      imagegrade: '',
      imageqid: '',
      imagequestion: '',
      imageadvice: '',
      imagerelation: '',
      imagerelation2: '',
      qimage: '',
      qvideo: '',
      advicelist: [],
      outDataList: [],
      selectedid: 0,
      filtergrade: '全学年',
      filtertopic: '全単元',
      filterstage: '小学校',
      filtercategory: '文章題・図形',
      errormessage: '',
      classAttention: 'uk-animation-fade',
      classCrop: 'uk-hidden',
      classLoading: 'uk-hidden',
      classResult: 'uk-hidden',
      classList: 'uk-hidden',
      classAdvice: 'uk-hidden',
      classError: 'uk-hidden',
    };

    this.evaluateAction = this.evaluateAction.bind(this);
    this.getAdviceList = this.getAdviceList.bind(this);
    this.handlefiltergrade = this.handlefiltergrade.bind(this);
    this.handlefiltertopic = this.handlefiltertopic.bind(this);
    this.handlefilterstage = this.handlefilterstage.bind(this);
    this.handlefiltercategory = this.handlefiltercategory.bind(this);
    this.handlegetadvice = this.handlegetadvice.bind(this);
    this.handlelistback = this.handlelistback.bind(this);
    this.handleadviceback = this.handleadviceback.bind(this);
    this.handlechangeselect = this.handlechangeselect.bind(this);
    this.rotateimage = this.rotateimage.bind(this);

    const url = 'https://dossa9mndc.execute-api.ap-northeast-1.amazonaws.com/default/getAdviceList';
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = '{}';

    fetch(url, {method, headers, body})
    .then((res)=> {
      return res.json();
    })
    .then((resJson) => {
      this.setState({advicelist: resJson['items']});
      
    })
    .catch((error) => {
      console.log(error)
    });
  }

  getAdviceList(){
    
    this.setState({classAttention: 'uk-container uk-hidden'});
    this.setState({classCrop: 'uk-container uk-hidden'});
    this.setState({classLoading: 'uk-container uk-hidden'})
    this.setState({classResult: 'uk-container uk-hidden'});
    this.setState({classList: 'uk-container uk-animation-fade uk-position-top uk-margin'});
    this.setState({classAdvice: 'uk-container uk-hidden'});
    this.setState({classError: 'uk-container uk-hidden'});
  }
  
  evaluateAction(event){
    this.setState({classAttention: 'uk-container uk-hidden'});
    this.setState({classCrop: 'uk-container uk-hidden'});
    this.setState({classLoading: 'uk-container uk-animation-slide-right'});
    this.setState({classResult: 'uk-container uk-hidden'});
    this.setState({classList: 'uk-container uk-hidden'});
    this.setState({classAdvice: 'uk-container uk-hidden'});
    this.setState({classError: 'uk-container uk-hidden'});

    const getputurl = 'https://j3vj0sso6c.execute-api.ap-northeast-1.amazonaws.com/default/getPuturl';
    fetch(getputurl)
    .then((res)=> {
      return res.json();
    })
    .then((resJson) => {
      puturl = resJson['puturl'];
      filepath = resJson['filepath'];

      fetch(puturl, {method: 'put', body: files})
      .then((data) => {
        
        console.log(data);
        this.setState({uploadURL: filepath});

        let url = '';
        let stage = '';

        if (this.state.filtercategory == '文章題・図形') {
          url = ' https://su2v369te4.execute-api.ap-northeast-1.amazonaws.com/default/evaluateimage';
        } else {
          url = 'https://p7v67zv3zf.execute-api.ap-northeast-1.amazonaws.com/default/evaluatecal';
        }
        const method = 'POST';
        const headers = {
          'Content-Type': 'application/json'
        };
        
        if (this.state.filterstage == '小学校'){
          stage = 0;
        } else if (this.state.filterstage == '中学校(中1のみ)') {
          stage = 1;
        }

        const body = '{"imageURL": "' + this.state.uploadURL + '", "stage": "' + stage + '"}';

        fetch(url, {method, headers, body})
          .then((res)=> {
            return res.json();
          })
          .then((resJson) => {
            console.log(resJson);
            if (resJson['statusCode'] == 400) {
              this.setState({errormessage: 'ごめんなさい！画像が大きすぎます。容量は4MB以内、サイズは4200 x 4200ピクセルより小さくしてください。'});
              this.setState({classAttention: 'uk-container uk-hidden'});
              this.setState({classCrop: 'uk-container uk-hidden'});
              this.setState({classLoading: 'uk-container uk-hidden'})
              this.setState({classResult: 'uk-container uk-hidden'});
              this.setState({classList: 'uk-container uk-hidden'});
              this.setState({classAdvice: 'uk-container uk-hidden'});
              this.setState({classError: 'uk-container uk-animation-fade'});
            } else {
              console.log(resJson['imageInfo'][0]['rawSubject']);
              console.log(resJson['imageInfo'][0]['subjectId']);
              console.log(resJson['imageInfo'][0]['question']);
              this.setState({
                outDataList: resJson['outData'],
                imagesubjectid: resJson['outData'][0]['subjectId'],
                imagesubjectname: resJson['outData'][0]['subjectName'],
                imagetopic: resJson['outData'][0]['topic'],
                imagegrade: resJson['outData'][0]['grade'],
                imageqId: resJson['outData'][0]['qId'],
                imagequestion: resJson['outData'][0]['question'],
                imageadvice: resJson['outData'][0]['advice'],
                imagerelation: resJson['outData'][0]['relation'],
                imagerelation2: resJson['outData'][0]['relation2'],
                qvideo: resJson['outData'][0]['qvideo'],
                selectedid: 0,
              });
              if ('qimage' in resJson['outData'][0]) {
                this.setState({qimage: resJson['outData'][0]['qimage']});
              } else {
                this.setState({qimage: noimage});
              }
              this.setState({classAttention: 'uk-container uk-hidden'});
              this.setState({classCrop: 'uk-container uk-hidden'});
              this.setState({classLoading: 'uk-container uk-hidden'})
              this.setState({classResult: 'uk-container uk-position-top uk-animation-fade uk-margin'});
              this.setState({classList: 'uk-container uk-hidden'});
              this.setState({classAdvice: 'uk-container uk-hidden'});
              this.setState({classError: 'uk-container uk-hidden'});
            }
          })
          .catch((error) => {
            this.setState({errormessage: 'ごめんなさい！どの問題かわかりませんでした！他の写真でためしてみてください。'});
            this.setState({classAttention: 'uk-container uk-hidden'});
            this.setState({classCrop: 'uk-container uk-hidden'});
            this.setState({classLoading: 'uk-container uk-hidden'})
            this.setState({classResult: 'uk-container uk-hidden'});
            this.setState({classList: 'uk-container uk-hidden'});
            this.setState({classAdvice: 'uk-container uk-hidden'});
            this.setState({classError: 'uk-container uk-animation-fade'});
            console.log(error)
          });
      })
      .catch((err) => {
        this.setState({errormessage: 'ごめんなさい！ファイルアップロードに失敗しました。もう一度ためしてください。'});
        this.setState({classAttention: 'uk-container uk-hidden'});
        this.setState({classCrop: 'uk-container uk-hidden'});
        this.setState({classLoading: 'uk-container uk-hidden'})
        this.setState({classResult: 'uk-container uk-hidden'});
        this.setState({classList: 'uk-container uk-hidden'});
        this.setState({classAdvice: 'uk-container uk-hidden'});
        this.setState({classError: 'uk-container uk-animation-fade'});
        console.error(err)
      })
    })
    .catch((error) => console.log(error));
  }

  uploadbutton(){
    document.getElementById('upload').click();
  }
  
  handlefiltergrade(){
    this.setState({filtergrade: document.getElementById('fg').value});
  }

  handlefiltertopic(){
    this.setState({filtertopic: document.getElementById('ft').value});
  }

  handlegetadvice(event){
    this.state.advicelist.filter(function(item, index){
      if (item.subjectId == event.currentTarget.id) {
        this.setState({
          imagesubjectid: item.subjectId,
          imagesubjectname: item.subjectName,
          imagetopic: item.topic,
          imagegrade: item.grade,
          imageqId: item.qI,
          imagequestion: item.question,
          imageadvice: item.advice,
          imagerelation: item.relation,
          imagerelation2: item.relation2,
          qvideo: item.qvideo,
        });
        if ('qimage' in item) {
          this.setState({qimage: item.qimage});
        } else {
          this.setState({qimage: noimage});
        }
      }
    }, this);
    this.setState({classAttention: 'uk-container uk-hidden'});
    this.setState({classCrop: 'uk-container uk-hidden'});
    this.setState({classLoading: 'uk-container uk-hidden'})
    this.setState({classResult: 'uk-container uk-hidden'});
    this.setState({classList: 'uk-container uk-hidden'});
    this.setState({classAdvice: 'uk-container uk-position-top uk-animation-fade uk-margin'});
    this.setState({classError: 'uk-container uk-hidden'});
    scroll.scrollToTop();
  }

  handlelistback(){
    this.setState({classAttention: 'uk-container uk-animation-fade'});
    this.setState({classCrop: 'uk-container uk-hidden'});
    this.setState({classLoading: 'uk-container uk-hidden'})
    this.setState({classResult: 'uk-container uk-hidden'});
    this.setState({classList: 'uk-container uk-hidden'});
    this.setState({classAdvice: 'uk-container uk-hidden'});
    this.setState({classError: 'uk-container uk-hidden'});
  }

  handleadviceback(){
    this.setState({classAttention: 'uk-container uk-hidden'});
    this.setState({classCrop: 'uk-container uk-hidden'});
    this.setState({classLoading: 'uk-container uk-hidden'})
    this.setState({classResult: 'uk-container uk-hidden'});
    this.setState({classList: 'uk-container uk-animation-fade uk-position-top uk-margin'});
    this.setState({classAdvice: 'uk-container uk-hidden'});
    this.setState({classError: 'uk-container uk-hidden'});
  }

  handlefilterstage(){
    this.setState({filterstage: document.getElementById('fs').value});
  }

  handlefiltercategory(){
    this.setState({filtercategory: document.getElementById('fc').value});
  }

  handlechangeselect(event){
    this.setState({
      imagesubjectid: this.state.outDataList[event.currentTarget.id].subjectId,
      imagesubjectname: this.state.outDataList[event.currentTarget.id].subjectName,
      imagetopic: this.state.outDataList[event.currentTarget.id].topic,
      imagegrade: this.state.outDataList[event.currentTarget.id].grade,
      imageqId: this.state.outDataList[event.currentTarget.id].qId,
      imagequestion: this.state.outDataList[event.currentTarget.id].question,
      imageadvice: this.state.outDataList[event.currentTarget.id].advice,
      imagerelation: this.state.outDataList[event.currentTarget.id].relation,
      imagerelation2: this.state.outDataList[event.currentTarget.id].relation2,
      qvideo: this.state.outDataList[event.currentTarget.id].qvideo,
      selectedid: event.currentTarget.id,
    });
    if ('qimage' in this.state.outDataList[event.currentTarget.id]) {
      this.setState({qimage: this.state.outDataList[event.currentTarget.id].qimage});
    } else {
      this.setState({qimage: noimage});
    }
    scroll.scrollToTop();
  }

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({
          src: reader.result,
          image_src: reader.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);
      angle = 0;
      this.setState({classAttention: 'uk-container uk-hidden'});
      this.setState({classCrop: 'uk-container uk-animation-fade uk-margin'});
      this.setState({classLoading: 'uk-container uk-hidden'})
      this.setState({classResult: 'uk-container uk-hidden'});
      this.setState({classList: 'uk-container uk-hidden'});
      this.setState({classAdvice: 'uk-container uk-hidden'});
      this.setState({classError: 'uk-container uk-hidden'});
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        files = blob;
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  rotateimage(){
    var img = document.getElementById("tmpimage");
    var canvas = document.createElement("canvas");
    angle += 90;
    if(angle == 360){
      angle = 0;
    }

    if(angle % 180 == 0){
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
    } else {
      canvas.width = img.naturalHeight;
      canvas.height = img.naturalWidth;  
    }

    var context = canvas.getContext("2d");
    context.translate(img.naturalWidth/2, img.naturalHeight/2);
    context.rotate(angle * Math.PI / 180);
    context.translate(-img.naturalWidth/2, -img.naturalHeight/2);
    
    if(angle == 0 || angle == 180){
      context.drawImage(img, 0, 0);
    } else if(angle == 270) {
      context.drawImage(img, -(img.naturalWidth-img.naturalHeight)/2 , -(img.naturalWidth-img.naturalHeight)/2);
    } else {
      context.drawImage(img, (img.naturalWidth-img.naturalHeight)/2 , (img.naturalWidth-img.naturalHeight)/2);
    }


    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        blob.name = "tmpimage.jpeg";
        window.URL.revokeObjectURL(this.tmpfileUrl);
        this.tmpfileUrl = window.URL.createObjectURL(blob);
        this.setState({ src: this.tmpfileUrl });
        resolve();
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div className="wrapper">
        <Helmet>

        </Helmet>
        <div className='uk-flex uk-flex-center uk-flex-middle'>          
          <div className={this.state.classAttention}>
            <img className='uk-align-center uk-margin-remove-bottom' src={title} alt="title" width="300" />
            <select id='fs' className="uk-select uk-width-1-1 uk-margin-top uk-margin-remove-bottom uk-text-center" value={this.state.filterstage} onChange={this.handlefilterstage}>
              <option>小学校</option>
              <option>中学校(中1のみ)</option>
              {/* <option>高校</option> */}
            </select><br/>
            <select id='fc' className="uk-select uk-width-1-1 uk-margin-remove-top uk-text-center" value={this.state.filtercategory} onChange={this.handlefiltercategory}>
              <option>文章題・図形</option>
              <option>計算</option>
            </select><br/>
            <button className="uk-button uk-button-primary uk-width-1-1 uk-margin-top" onClick={this.uploadbutton}>写真をとる</button>            
            <button className="uk-button uk-button-text uk-align-center uk-margin-top" onClick={this.getAdviceList}>問題リスト ▶</button>
          </div>
          <div className="uk-position-bottom-center">
            <div className={this.state.classAttention}>
              <div className="uk-text-center uk-text-small">
                高校生以上の方は<a href="https://studymeter-web.com/"  target='_blank'>こちら</a><br />
                数学やAIを学べるオンラインスクール
              </div>     
            </div>
          </div>
            
          <div className={this.state.classLoading}>
            <img className='uk-align-center' src={Loading} alt="loading" />
            <p className='uk-text-center uk-text-bold'>問題をしらべています...</p>
          </div>

          <div className={this.state.classResult}>
            <button className="uk-button uk-button-text" onClick={this.handlelistback}>◀ 最初にもどる</button>          
            <ul className="uk-margin-remove-bottom uk-subnav uk-subnav-pill">
              {this.state.outDataList.map(function(index, i) {
                if (i == this.state.selectedid){
                  return <li id={i} onClick={this.handlechangeselect} className='uk-active'><a className="uk-text-bold" href="#">ヒント{i + 1}</a></li>;
                } else {
                  return <li id={i} onClick={this.handlechangeselect}><a href="#">ヒント{i + 1}</a></li>;
                }
              }, this)}
            </ul>
            <div className="uk-margin-remove-top uk-card uk-card-default uk-card-small">
              <div className="uk-card-header">
                  <div className="uk-grid-small uk-flex-middle">
                      <div className="uk-width-expand">
                          <span className="uk-label uk-margin-remove-bottom">{this.state.imagegrade}</span>
                          <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{this.state.imagetopic}</span>
                          <h3 className="uk-card-title uk-margin-remove-top uk-margin-remove-bottom">{this.state.imagequestion}</h3>
                          <p className="uk-text-meta uk-margin-remove-top">{this.state.imagesubjectname}</p>
                      </div>
                  </div>
              </div>              
              <div className="uk-card-body">
                <h5 className="uk-heading-bullet uk-text-bold">これなら解ける？<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="写真をとった問題と同じ考え方で解ける、基本問題です。これなら解けますか？"></a></h5>
                <img src={this.state.qimage}  alt="これなら解けるかな？"/>
                <h5 className="uk-heading-bullet uk-text-bold">こう考えよう！<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="この問題を解くためのヒントです。これを読んで、もう少し考えてみよう！"></a></h5>
                <p>{this.state.imageadvice}</p>
                {this.state.qvideo && (
                  <iframe width="100%" src={this.state.qvideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                )}
              </div>
            </div>
            <h5 class="uk-heading-line uk-text-left uk-text-bold"><span>◀ わからなければ復習しよう！<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="この問題を解くのに必要な知識です。ヒントを見ても分からなければ、戻って復習しよう！"></a></span></h5>
            {this.state.advicelist.map(function(index) {
              if (index.visible == 'Y'){
                if (index.subjectId==this.state.imagerelation || index.subjectId==this.state.imagerelation2) {
                  return <div className="uk-margin uk-card uk-card-default uk-card-small" id={index.subjectId} onClick={this.handlegetadvice}>
                    <div className="uk-card-body">
                      <span className="uk-label uk-margin-remove-bottom">{index.grade}</span>
                      <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{index.topic}</span>
                      <h4 className="uk-margin-remove-top uk-margin-remove-bottom">{index.question}</h4>
                      <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">{index.subjectName}</p>
                    </div>
                  </div>;
                }
              }
            }, this)}
            {/* <h5 class="uk-heading-line uk-text-right uk-text-bold"><span>できるようになったら ▶</span></h5>
            {this.state.advicelist.map(function(index) {
              if (index.relation==this.state.imagesubjectid || index.relation2==this.state.imagesubjectid) {
                return <div className="uk-margin uk-card uk-card-default uk-card-small" id={index.subjectId} onClick={this.handlegetadvice}>
                  <div className="uk-card-body">
                    <span className="uk-label uk-margin-remove-bottom">{index.grade}</span>
                    <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{index.topic}</span>
                    <h4 className="uk-margin-remove-top uk-margin-remove-bottom">{index.question}</h4>
                    <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">{index.subjectName}</p>
                  </div>
                </div>;
              }
            }, this)} */}
            {/* <button className="uk-button uk-button-primary uk-align-center" onClick={this.uploadbutton}>ほかの写真をとる</button> */}
          </div>

          <div className={this.state.classList}>
            <button className="uk-button uk-button-text uk-align-left" onClick={this.handlelistback}>◀ 最初にもどる</button>          
            <select id='fg' className="uk-select" value={this.state.filtergrade} onChange={this.handlefiltergrade}>
              <option>全学年</option>
              <option>小1</option>
              <option>小2</option>
              <option>小3</option>
              <option>小4</option>
              <option>小5</option>
              <option>小6</option>
              <option>中1</option>
            </select>
            <select id='ft' className="uk-margin-small uk-select" value={this.state.filtertopic} onChange={this.handlefiltertopic}>
              <option>全単元</option>
              <option>数・計算・式</option>
              <option>図形</option>
              <option>測定・データ</option>
              <option>変化・関係・関数</option>
            </select>
            {this.state.advicelist.map(function(index) {
              if (index.visible == 'Y'){
                if (this.state.filtergrade=='全学年' || index.grade==this.state.filtergrade) {
                  if (this.state.filtertopic=='全単元' || index.topic==this.state.filtertopic) {
                    return <div className="uk-margin uk-card uk-card-default uk-card-small" id={index.subjectId} onClick={this.handlegetadvice}>
                      <div className="uk-card-body">
                        <span className="uk-label uk-margin-remove-bottom">{index.grade}</span>
                        <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{index.topic}</span>
                        <h4 className="uk-margin-remove-top uk-margin-remove-bottom">{index.question}</h4>
                        <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">{index.subjectName}</p>
                      </div>
                    </div>;
                  }
                }
              }
            }, this)}
          </div>

          <div className={this.state.classAdvice}>
            <button className="uk-button uk-button-text" onClick={this.handleadviceback}>◀ 問題リスト</button>
            <div className="uk-margin uk-card uk-card-default uk-card-large">
              <div className="uk-card-header">
                  <div className="uk-grid-small uk-flex-middle">
                      <div className="uk-width-expand">
                          <span className="uk-label uk-margin-remove-bottom">{this.state.imagegrade}</span>
                          <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{this.state.imagetopic}</span>
                          <h3 className="uk-card-title uk-margin-remove-top uk-margin-remove-bottom">{this.state.imagequestion}</h3>
                          <p className="uk-text-meta uk-margin-remove-top">{this.state.imagesubjectname}</p>
                          {/* <button class="uk-button uk-button-default uk-margin-small-right" type="button" uk-toggle="target: #modal-center">Open</button> */}
                      </div>
                  </div>
              </div>              
              <div className="uk-card-body">
                <h5 className="uk-heading-bullet uk-text-bold">やってみよう！<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="このジャンルの基本問題です。解けるかどうか、確認しよう！"></a></h5>
                <img src={this.state.qimage}  alt="やってみよう！"/>
                <h5 className="uk-heading-bullet uk-text-bold">こう考えよう！<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="この問題を解くためのヒントです。これを読んで、もう少し考えてみよう！"></a></h5>
                <p>{this.state.imageadvice}</p>
                {this.state.qvideo && (
                  <iframe width="100%" src={this.state.qvideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                )}
              </div>
            </div>
            <h5 class="uk-heading-line uk-text-left uk-text-bold"><span>◀ わからなければ復習しよう！<a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="この問題を解くのに必要な知識です。ヒントを見ても分からなければ、戻って復習しよう！"></a></span></h5>
            {this.state.advicelist.map(function(index) {
              if (index.visible == 'Y'){
                if (index.subjectId==this.state.imagerelation || index.subjectId==this.state.imagerelation2) {
                  return <div className="uk-margin uk-card uk-card-default uk-card-small" id={index.subjectId} onClick={this.handlegetadvice}>
                    <div className="uk-card-body">
                      <span className="uk-label uk-margin-remove-bottom">{index.grade}</span>
                      <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{index.topic}</span>
                      <h4 className="uk-margin-remove-top uk-margin-remove-bottom">{index.question}</h4>
                      <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">{index.subjectName}</p>
                    </div>
                  </div>;
                }
              }
            }, this)}
            <h5 class="uk-heading-line uk-text-right uk-text-bold"><span><a href="javascript:void(0);" className="uk-icon-link" uk-icon="icon: commenting; ratio: 0.9" uk-tooltip="この問題の知識を使う、次の単元です。できるようになったら進もう！"></a>できるようになったら ▶</span></h5>
            {this.state.advicelist.map(function(index) {
              if (index.visible == 'Y'){
                if (index.relation==this.state.imagesubjectid || index.relation2==this.state.imagesubjectid) {
                  return <div className="uk-margin uk-card uk-card-default uk-card-small" id={index.subjectId} onClick={this.handlegetadvice}>
                    <div className="uk-card-body">
                      <span className="uk-label uk-margin-remove-bottom">{index.grade}</span>
                      <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{index.topic}</span>
                      <h4 className="uk-margin-remove-top uk-margin-remove-bottom">{index.question}</h4>
                      <p className="uk-text-meta uk-margin-remove-top uk-margin-remove-bottom">{index.subjectName}</p>
                    </div>
                  </div>;
                }
              }
            }, this)}
          </div>

          <div id="modal-center" className="uk-flex-top" uk-modal="true">
            <div className="uk-modal-dialog uk-margin-auto-vertical">
              <div class="uk-modal-header">
                <span className="uk-label uk-margin-remove-bottom">{this.state.imagegrade}</span>
                <span className="uk-label-success uk-margin-remove-bottom uk-margin-small-left">{this.state.imagetopic}</span>
                <h3 className="uk-margin-remove-top uk-margin-remove-bottom">{this.state.imagesubjectname}</h3>
              </div>
              <div className="uk-modal-body">
                <button className="uk-modal-close-default" type="button" uk-close="true"></button>
                <div class="uk-position-relative uk-dark" uk-slideshow="ratio: 6:5">
                  <ul class="uk-slideshow-items">
                      <li>
                        <img src="https://studyimagestock.s3-ap-northeast-1.amazonaws.com/oimage/KZ_001_001_01.png"  alt="この単元の基礎" uk-cover="true"/>
                      </li>
                      <li>
                        <img src="https://studyimagestock.s3-ap-northeast-1.amazonaws.com/oimage/KZ_001_001_02.png"  alt="この単元の基礎" uk-cover="true"/>
                      </li>
                      <li>
                        <img src="https://studyimagestock.s3-ap-northeast-1.amazonaws.com/oimage/KZ_001_001_03.png"  alt="この単元の基礎" uk-cover="true"/>
                      </li>
                  </ul>

                  <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>
              </div>
            </div>
          </div>

          <div className={this.state.classError}>
            <button className="uk-button uk-button-text" onClick={this.handlelistback}>◀ 最初にもどる</button>
            <div className="uk-margin uk-card uk-card-default uk-card-large">
              <div className="uk-card-header">
                  <div className="uk-grid-small uk-flex-middle">
                      <div className="uk-width-expand">
                          <h3 className="uk-card-title uk-margin-remove-top uk-margin-remove-bottom">分析できませんでした...</h3>
                      </div>
                  </div>
              </div>              
              <div className="uk-card-body">
                <p>{this.state.errormessage}</p>
              </div>
            </div>
          </div>

          <div className='fixed'>
            <a href='https://note.com/shufugaishi/n/nd3f3dee375e3' target='_blank'>
              <img className='uk-align-center' src={help} width='35px' alt="つかいかた" />
            </a>
          </div>

          <div className={this.state.classCrop}>
            <button className="uk-button uk-button-text uk-align-left" onClick={this.handlelistback}>◀ 最初にもどる</button>          
            <input id="upload" className='uk-hidden' type="file" accept="image/*" ref="file" onChange={this.onSelectFile}/><br />
            {src && (
              <ReactCrop
                id="ulimage"
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            <img id="tmpimage" className="uk-hidden" alt="プレビュー" src={this.state.image_src} /><br />
            {croppedImageUrl && (
              <img id="croppedImage" className="uk-hidden" alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
            )}
            <div className="uk-position-bottom-center">
              <ul className="uk-thumbnav uk-margin">
                <li><img src={rotate} width='40px' alt="回転する" onClick={this.rotateimage}/></li>
                <li><img src={go} width='40px' alt="分析する" onClick={this.evaluateAction}/></li>
              </ul>
            </div>

          </div>
        </div>

      </div>

    );  
  }
}

export default App;
